import { useEffect, useState } from "react";
import "../../Styles/stats.scss";
import CustomPieChart from "./CustomPieChart";
import CustomBarChart from "./CustomBarChart";
import billing_effect from "../../assets/imgs/billing_effect.png";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import html2canvas from "html2canvas";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";
import { BiSolidMap } from "react-icons/bi";

export default function Stats(props) {
  const [data, setData] = useState(null);
  const [projects, setProjects] = useState(null);
  const chrt = useRef(null);
  const [showing, setShowing] = useState(true);
  const [aspect, setAspect] = useState(1.5);
  const [aspect1, setAspect1] = useState(1.5);

  const p1ref = useRef();
  const p2ref = useRef();
  const p3ref = useRef();

  const b1ref = useRef();
  const b2ref = useRef();

  const h1ref = useRef();
  const h2ref = useRef();

  useLayoutEffect(() => {
    const { width, height } = p1ref.current.getBoundingClientRect();

    setAspect(width / height);
    setAspect1(width / (height * 0.8));
  }, []);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    setShowing(false);
    setTimeout(() => {
      setShowing(true);
    }, 1);
  }, [props.showing]);

  useEffect(() => {
    fetch(`/api/customers/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    fetch(`/api/customers/all/charts`)
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch data!!!");
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setProjects(data);
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      {showing && (
        <div className="stats">
          <div className="top">
            <TopItem
              title="Mapped Customers"
              amt={data ? data?.Customers[0].total : 0}
              color="#C9EEFF"
              color1="#C9EEFF"
            />
            <TopItem
              title="Mapped DMA"
              amt={data ? data?.DMA[0].total : 0}
              color="#F1DEC9"
              color1="#F1DEC9"
            />
            <TopItem
              title="Mapped Zone"
              amt={data ? data?.Zone[0].total : 0}
              color="#FBFFB1"
              color1="#FBFFB1"
            />
            <TopItem
              title="Mapped Tanks"
              amt={data ? data?.Tanks[0].total : 0}
              color="#FFA3FD"
              color1="#FFA3FD"
            />
            <TopItem
              title="Mapped Valves"
              amt={data ? data?.Valves[0].total : 0}
              color="#C9F4AA"
              color1="#C9F4AA"
            />
            <TopItem
              title="Mapped Manholes"
              amt={data ? data?.Manholes[0].total : 0}
              color="#FFFBEB"
              color1="#FFFBEB"
            />
          </div>
          <div className="pies">
            <div ref={p1ref} className="chart">
              <h3>Account Type</h3>
              {projects && (
                <CustomPieChart data={projects?.AccountType} aspect={aspect} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.AccountType);
                  }}
                />
              </div>
            </div>
            <div ref={p2ref} className="chart">
              <h3>Account Status</h3>
              {projects && (
                <CustomPieChart
                  data={projects?.AccountStatus}
                  aspect={aspect}
                />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.AccountStatus);
                  }}
                />
              </div>
            </div>
            <div ref={p3ref} className="chart">
              <h3>Meter Status</h3>
              {projects && (
                <CustomPieChart data={projects?.MeterStatus} aspect={aspect} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(p3ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.MeterStatus);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="middle">
            <div ref={b1ref} className="chart">
              <h3>Distribution by DMAs</h3>
              {projects && (
                <CustomBarChart data={projects?.DMA} aspect={aspect1} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(b1ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.DMA);
                  }}
                />
              </div>
            </div>
            <div ref={b2ref} className="chart">
              <h3>Distribution by Meter Class</h3>
              {projects && (
                <CustomBarChart data={projects?.Class} aspect={aspect1} />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(b2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Class);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bottom">
            <div ref={b2ref} className="chart">
              <h3>Distribution by Zone</h3>
              {projects && (
                <CustomBarChart
                  data={projects?.Zone}
                  aspect={props.showing ? 3 : 1}
                />
              )}
              <div className="save">
                <BsImages
                  color="blue"
                  onClick={() => {
                    handleDownloadImage(b2ref);
                  }}
                />
                <BsFiletypeCsv
                  color="blue"
                  onClick={() => {
                    saveData(projects?.Zone);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const TopItem = (props) => {
  const [value, setValue] = useState(props.amt);
  const [animate, setAnimate] = useState(false);
  const vl = useRef();

  useEffect(() => {
    animateValue(vl.current, 0, props.amt, 1000);
  }, [props.amt]);

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = withCommas(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="tp_item">
      <img src={billing_effect} alt="" className="img" />
      <BiSolidMap size={32} className="icon" />
      <h1 ref={vl}></h1>
      <p>{props.title}</p>
    </div>
  );
};
