import "../../Styles/billingstats.scss";
import { useRef, useState } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import XYZ from "ol/source/XYZ";
import Overlay from "ol/Overlay";
import { MdOutlinePayments } from "react-icons/md";
import { FcPaid } from "react-icons/fc";
import ReactDOMServer from "react-dom/server";

import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import Graticule from "ol/layer/Graticule";

export default function BillingData(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isloading, setIsLoading] = useState(false);

  const [editing, setEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState(false);
  const [column, setColumn] = useState("Name");
  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);

  useEffect(() => {
    if (!fcolumn || !fvalue || !foperator) {
      setIsLoading(true);
      console.log(offset);
      fetch(`/api/customerbilling/paginated/${offset} * 12`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data?.data?.length > 0) {
            setData(data);
          } else setData(null);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      fetch(
        `/api/customerbilling/filter/${fcolumn}/${foperator}/${fvalue}/${offset} * 12`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data?.data?.length > 0) {
            setData(data);
          } else setData(null);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [offset, refresh, fvalue, foperator, fcolumn]);

  function searchCustomer(v) {
    setIsLoading(true);
    fetch(`/api/customerbilling/search/${column}/${v}/${offset} * 12`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          setData(data);
        } else setData(null);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="billingstats">
      <div className="list">
        <div className="bar">
          <div className="left">
            <h3>Billing</h3>
            <p>Keep track of billing statistics</p>
          </div>

          <div className="search">
            <select
              onChange={(e) => {
                setColumn(e.target.value);
              }}
              name=""
              id=""
            >
              <option value="Name">Name</option>
              <option value="Account">Account No</option>
              <option value="Phone">Phone No</option>
              <option value="Zone">Zone</option>
              <option value="Route">Route</option>
              <option value="MeterStatus">Meter Status</option>
              <option value="AccountStatus">Account Status</option>
              <option value="DueDate">Invoice Due Date</option>
              <option value="CreatedDate">Invoice Creation Date</option>
            </select>
            <input
              onChange={(e) => {
                if (e.target.value == "") {
                  setRefresh(!refresh);
                  setOffset(0);
                } else {
                  searchCustomer(e.target.value);
                }
              }}
              type="text"
              placeholder="Name search"
              required
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
        <div className="filter">
          <div
            onClick={() => {
              if (!filter) {
                setFilter(true);
                setFColumn("Amount");
                setFOperator("=");
              } else {
                setFilter(false);
                setFColumn(null);
                setFOperator(null);
                setFValue(null);
              }
            }}
            className="add"
          >
            <i className="fa fa-plus-circle"></i>
            <p>{filter ? "Close Filter" : "Add Filter"}</p>
          </div>
          {filter && (
            <div className="math">
              <select
                onChange={(e) => {
                  setFColumn(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="Amount">Invoice Amount</option>
                <option value="PreviousBalance">Previous Balance</option>
                <option value="CurrentBalance">Current Balance</option>
              </select>
              <select
                onChange={(e) => {
                  setFOperator(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="=">Equal To</option>
                <option value=">">Greater Than</option>
                <option value="<">Less Than</option>
              </select>
              <input
                onChange={(e) => {
                  if (e.target.value == "") {
                    setFValue(null);
                  } else {
                    setFValue(e.target.value);
                  }
                }}
                type="number"
                name=""
                id=""
                placeholder="Value"
                required
              />
              <button
                onClick={() => {
                  setFilter(false);
                }}
              >
                Add
              </button>
            </div>
          )}
          {fcolumn && foperator && fvalue && (
            <div className="filter">
              <p>{fcolumn}</p>
              <h6>{foperator}</h6>
              <p>{fvalue}</p>
              <i
                onClick={() => {
                  setFColumn(null);
                  setFOperator(null);
                  setFValue(null);
                }}
                className="fa fa-times"
              ></i>
            </div>
          )}
        </div>
        <div className="container">
          <div className="head">
            <h4></h4>
            <h4>Name</h4>
            <h4>Account Status</h4>
            <h4>Current Balance</h4>
            <h4>Invoiced Amount</h4>
          </div>
          {data &&
            data?.data?.length > 0 &&
            data?.data?.map((item, i) => {
              return <Item key={i} index={i} item={item} />;
            })}
          <br />
          <br />
          {data?.total > 0 && (
            <Pagination
              totalItems={data?.total}
              onPageChange={(v) => {
                setOffset(v);
              }}
              currentPage={offset}
            />
          )}
        </div>
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const Popup = (props) => {
  const [subcounty, setSubCounty] = useState(
    new VectorLayer({ title: "Sub Counties" })
  );
  const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 2,
        lineDash: [0.5, 8],
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid",
    })
  );
  const [data, setData] = useState(null);
  const [active, setActive] = useState("Data");
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  mapElement.current = map;

  useEffect(() => {
    if (active == "Map") {
      basemap.setSource(
        new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}" +
            "?access_token=pk.eyJ1IjoiZ2F0aG9nbzEiLCJhIjoiY2t0djhndnB4MGkzdDJucDg2bW5uNXNrcyJ9.mnbTMXxDrdYnTrb8Gr7_MA",
          crossOrigin: "anonymous",
        })
      );
      const initialMap = new Map({
        target: mapElement.current,
        layers: [basemap, subcounty, ward, graticule],
        view: new View({
          projection: "EPSG:4326",
          center: [36.45, -0.1],
          zoom: 12,
          maxZoom: 20,
        }),
        controls: defaultControls().extend([
          new ZoomToExtent({
            extent: [34.36168, 0.41839, 35.06887, 1.14702],
          }),
          new ScaleLine({
            units: "metric",
            bar: false,
            text: "Scale",
          }),
        ]),
      });

      setMap(initialMap);
    }
  }, [active]);

  useEffect(() => {
    if (active == "Map" && map) {
      fetch(`/api/customers/details/${props.data.Account}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          console.log(data);
          if (data.length > 0) {
            const geojsonObject = {
              type: "Feature",
              geometry: data[0].geom,
              properties: data[0],
            };
            const vectorSource = new VectorSource({
              features: new GeoJSON().readFeatures(geojsonObject),
            });

            const vectorLayer = new VectorLayer({
              source: vectorSource,
              style: new Style({
                image: new CircleStyle({
                  radius: 10,
                  fill: new Fill({
                    color: "rgba(255, 0, 0, 0.6)",
                  }),
                  stroke: new Stroke({
                    color: "#319FD3",
                    width: 1,
                  }),
                }),
              }),
            });

            map.addLayer(vectorLayer);

            // Create a popup overlay
            const popup = new Overlay({
              element: document.getElementById("popup"),
              positioning: "bottom-center",
              stopEvent: false,
              offset: [0, -10],
            });
            map.addOverlay(popup);

            // Add a click event to the vector layer to show the popup
            map.on("click", (event) => {
              map.forEachFeatureAtPixel(event.pixel, (feature) => {
                const coordinates = feature.getGeometry().getCoordinates();
                popup.setPosition(coordinates);
                document.getElementById("popup-content").innerHTML =
                  ReactDOMServer.renderToString(<MapPopup data={data[0]} />);
                popup.setOffset([0, -20]); // Adjust the popup offset to prevent it from blocking the feature
              });
            });

            const extent = vectorSource.getExtent();
            map.getView().fit(extent, { padding: [50, 50, 50, 50] });

            return () => {
              map.setTarget(null);
            };
          }
        })
        .catch((e) => {});
    }
  }, [active, map]);

  const MapPopup = (params) => {
    return (
      <div className="mapopup">
        <Row item={["Name", params.data.Name]} />
        <Row item={["Route", params.data.Route]} />
        <Row item={["Zone", params.data.Zone]} />
        <Row item={["DMA", params.data.DMA]} />
        <Row item={["Location", params.data.Location]} />
      </div>
    );
  };

  useEffect(() => {
    const cols = Object.entries(props.data);
    setData(cols);
  }, []);

  const Row = (params) => {
    return (
      <div className="prow">
        <p>{params.item[0]}</p>
        <p>{params.item[1]}</p>
      </div>
    );
  };

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={params.active == params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div data-aos="fade-left" className="bpopup">
      <div className="bcontainer">
        <div className="dets">
          <h3>{props?.data.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="pbar">
          <Bar txt="Data" active={active} />
          <Bar txt="Map" active={active} />
        </div>
        {active == "Data" && (
          <div className="content">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return <Row key={i} item={item} />;
              })}
          </div>
        )}
        {active == "Map" && (
          <div>
            <div ref={mapElement} className="pmap"></div>
            <div id="popup" className="ol-popup">
              <div id="popup-content"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const [showing, setShowing] = useState(null);
  const [cl, setCl] = useState("white");
  const colors = [
    { name: "CHANGE_OF_NAME", color: "#898121" },
    { name: "CANCELLED", color: "#FFEADD" },
    { name: "DORMANT", color: "#E7B10A" },
    { name: "ACTIVE", color: "#1A5D1A" },
    { name: "PENDING_PAYMENT", color: "#FF9B9B" },
    { name: "SEALED", color: "#4E4FEB" },
    { name: "CUT-OFF", color: "#068FFF" },
  ];

  useEffect(() => {
    const i = colors
      .map((e) => {
        return e.name;
      })
      .indexOf(props.item.AccountStatus);
    if (i != -1) {
      setCl(colors[i].color);
    }
  }, [props.item.AccountStatus]);

  function withCommas(x) {
    return (Math.round(x * 100) / 100)
      .toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      {" "}
      <div
        className="row"
        onClick={() => {
          setShowing(true);
        }}
      >
        {props.item.Amount == 0 ? (
          <FcPaid color="green" className="icon" size={32} />
        ) : (
          <MdOutlinePayments color="blue" className="icon" size={32} />
        )}
        <div>
          <p>{props.item.Name?.toLowerCase()}</p>
          <p>
            <span>Account No: </span>
            {props.item.Account}
          </p>
        </div>
        <p style={{ backgroundColor: cl, color: "white", fontSize: "x-small" }}>
          {props.item.AccountStatus?.toLowerCase()}
        </p>
        <div>
          <p>
            <span>Current Balance: KSh</span>{" "}
            {withCommas(props.item.CurrentBalance)}
          </p>
          <p>
            <span>Previous Balance: KSh</span>{" "}
            {withCommas(props.item.CurrentBalance)}
          </p>
        </div>
        <p>
          <span>KSh </span> {withCommas(props.item.Amount)}
        </p>
      </div>
      {showing && (
        <Popup showing={showing} setShowing={setShowing} data={props.item} />
      )}
    </>
  );
};
