import "../../Styles/data.scss";
import { useState, useRef } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import XYZ from "ol/source/XYZ";
import Overlay from "ol/Overlay";
import { isMobile } from "react-device-detect";
import ReactDOMServer from "react-dom/server";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import Graticule from "ol/layer/Graticule";

export default function Data(props) {
  const [data, setData] = useState(null);
  const [head, setHead] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searched, setSearched] = useState(null);
  const [column, setColumn] = useState("Name");
  const [v, setV] = useState("");
  const [offset, setOffset] = useState(0);
  const pathname = window.location.pathname.split("/");
  const asset = pathname[2].toLowerCase();

  useEffect(() => {
    setIsLoading(true);
    setHead(null);
    setData(null);

    if (!v) {
      fetch(`/api/${props.url}/${offset} * 12`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data?.data?.length > 0) {
            const cols = Object.keys(data.data[0]);
            let d = [];
            cols.forEach((item) => {
              if (
                item !== "ID" &&
                item !== "Longitude" &&
                item !== "Latitude" &&
                item !== "ObjectID" &&
                item !== "Image" &&
                item !== "NRWUserID" &&
                item !== "UserID" &&
                item !== "geom" &&
                !(asset === "valves" && item === "Name")
              ) {
                d.push(item);
              }
            });
            if (isMobile) {
              setCount(2);
            } else {
              let c = d.length > 5 ? 5 : d.length;
              setCount(c);
            }
            setHead(d);
            setData(data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      searchTank(v);
    }
  }, [currentPage, v, offset, refresh]);

  async function searchTank(v) {
    setIsLoading(true);
    fetch(`/api/${asset}/paginated/search/${column}/${v}/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item !== "ID" &&
              item !== "Longitude" &&
              item !== "Latitude" &&
              item !== "ObjectID" &&
              item !== "Image" &&
              item !== "NRWUserID" &&
              item !== "UserID" &&
              item !== "geom" &&
              !(asset === "valves" && item === "Name")
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const saveData = (data) => {
    let rows = [];
    rows.push(Object.keys(data?.data[0]));
    data?.data?.forEach((item) => {
      // Map each item's values and enclose them in double quotes
      let rowValues = Object.values(item).map((value) => `"${value}"`);
      rows.push(rowValues);
    });

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="data">
      <div className="list">
        <div className="dtitle">
          <h3>{props.title}</h3>
          <div className="search">
            <select
              onChange={(e) => {
                setColumn(e.target.value);
              }}
              name=""
              id=""
            >
              {asset == "valves" && <option value="Type">Type</option>}
              {asset != "valves" && <option value="Name">Name</option>}
              <option value="Account No">Area</option>
              <option value="Zone">Location</option>
              <option value="Zone">Zone</option>
              <option value="DMA">DMA</option>
              <option value="Route">Route</option>
            </select>
            <input
              onChange={(e) => {
                if (e.target.value == "") {
                  setRefresh(!refresh);
                  setOffset(0);
                } else {
                  setV(e.target.value);
                }
              }}
              type="text"
              placeholder="Select column to search"
              required
            />
            <i className="fa fa-search"></i>
            {searched && (
              <FontAwesomeIcon
                onClick={() => {
                  saveData(searched);
                }}
                className="download"
                icon={faDownload}
              />
            )}
          </div>
        </div>
        <hr />
        <div
          style={{
            gridTemplateColumns: `repeat(${head ? count : 0},1fr)`,
          }}
          className="head"
        >
          {head &&
            head.length > 0 &&
            head?.map((item, i) => {
              if (i < count) {
                return <h4 key={i}>{item}</h4>;
              }
            })}
        </div>
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <Item
                setShowing={setShowing}
                key={i}
                index={i}
                data={item}
                head={head}
                count={count}
                url={props.url}
              />
            );
          })}

        {data?.total > 0 && (
          <Pagination
            totalItems={data?.total}
            onPageChange={(v) => {
              setOffset(v);
            }}
            currentPage={offset}
          />
        )}
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const Popup = (props) => {
  const [subcounty, setSubCounty] = useState(
    new VectorLayer({ title: "Sub Counties" })
  );
  const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 2,
        lineDash: [0.5, 8],
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid",
    })
  );
  const [data, setData] = useState(null);
  const [active, setActive] = useState("Data");
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  mapElement.current = map;

  useEffect(() => {
    if (active == "Map") {
      basemap.setSource(
        new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}" +
            "?access_token=pk.eyJ1IjoiZ2F0aG9nbzEiLCJhIjoiY2t0djhndnB4MGkzdDJucDg2bW5uNXNrcyJ9.mnbTMXxDrdYnTrb8Gr7_MA",
          crossOrigin: "anonymous",
        })
      );
      const initialMap = new Map({
        target: mapElement.current,
        layers: [basemap, subcounty, ward, graticule],
        view: new View({
          projection: "EPSG:4326",
          center: [36.45, -0.1],
          zoom: 12,
          maxZoom: 20,
        }),
        controls: defaultControls().extend([
          new ZoomToExtent({
            extent: [34.36168, 0.41839, 35.06887, 1.14702],
          }),
          new ScaleLine({
            units: "metric",
            bar: false,
            text: "Scale",
          }),
        ]),
      });

      setMap(initialMap);
    }
  }, [active]);

  useEffect(() => {
    if (active == "Map" && map) {
      fetch(`/api/${props.url.split("/")[1]}/${props.data.ID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          const geojsonObject = {
            type: "Feature",
            geometry: data.geom,
            properties: data,
          };
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonObject),
          });

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({
                  color: "rgba(255, 0, 0, 0.6)",
                }),
                stroke: new Stroke({
                  color: "#319FD3",
                  width: 1,
                }),
              }),
            }),
          });

          map.addLayer(vectorLayer);

          // Create a popup overlay
          const popup = new Overlay({
            element: document.getElementById("popup"),
            positioning: "bottom-center",
            stopEvent: false,
            offset: [0, -10],
          });
          map.addOverlay(popup);

          // Add a click event to the vector layer to show the popup
          map.on("click", (event) => {
            map.forEachFeatureAtPixel(event.pixel, (feature) => {
              const coordinates = feature.getGeometry().getCoordinates();
              popup.setPosition(coordinates);
              document.getElementById("popup-content").innerHTML =
                ReactDOMServer.renderToString(<MapPopup data={data} />);
              popup.setOffset([0, -20]); // Adjust the popup offset to prevent it from blocking the feature
            });
          });

          const extent = vectorSource.getExtent();
          map.getView().fit(extent, { padding: [50, 50, 50, 50] });

          return () => {
            map.setTarget(null);
          };
        })
        .catch((e) => {});
    }
  }, [active, map]);

  const MapPopup = (params) => {
    return (
      <div className="mapopup">
        <Row item={["Name", params.data.Name]} />
        <Row item={["Route", params.data.Route]} />
        <Row item={["Zone", params.data.Zone]} />
        <Row item={["DMA", params.data.DMA]} />
        <Row item={["Location", params.data.Location]} />
      </div>
    );
  };

  useEffect(() => {
    let d = props.data;
    delete d.geom;
    const cols = Object.entries(d);

    setData(cols);
  }, []);

  const Row = (params) => {
    return (
      <div className="prow">
        <p>{params.item[0]}</p>
        <p>{params.item[1]}</p>
      </div>
    );
  };

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={params.active == params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  return (
    <div data-aos="fade-left" className="bpopup">
      <div className="bcontainer">
        <div className="dets">
          <h3>{props?.data.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="pbar">
          <Bar txt="Data" active={active} />
          <Bar txt="Map" active={active} />
        </div>
        {active == "Data" && (
          <div className="content">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return <Row key={i} item={item} />;
              })}
          </div>
        )}
        {active == "Map" && (
          <div>
            <div ref={mapElement} className="pmap"></div>
            <div id="popup" className="ol-popup">
              <div id="popup-content"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const [showing, setShowing] = useState(null);
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <>
      <div
        style={{
          gridTemplateColumns: `repeat(${props.head ? props.count : 0},1fr)`,
          backgroundColor: cl,
        }}
        className="row"
        onClick={() => {
          setShowing(true);
        }}
      >
        {props.head?.map((item, i) => {
          if (i < props.count) {
            return <p key={i}>{props.data[item]}</p>;
          }
        })}
      </div>
      {showing && (
        <Popup
          showing={showing}
          setShowing={setShowing}
          url={props.url}
          data={props.data}
        />
      )}
    </>
  );
};
