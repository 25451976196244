import MyMap from "./Map";
import Reports from "./ReportsMap";
import "../../Styles/gis.scss";

export default function IncidencesAllMAp(props) {
  const pathname = window.location.href.split("/")[4];
  return (
    <div className="gis">
      <div className="map">
        <Reports active={pathname} url={props.url} />
      </div>
    </div>
  );
}
