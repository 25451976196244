import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import Stats from "../components/Stats/Stats";
import { useEffect } from "react";
import MobileUsers from "../components/Users/MobileUsers";
import PortalUsers from "../components/Users/PortalUsers";
import NewMobileUser from "../components/Users/NewMobileUser";
import NewPortalUser from "../components/Users/NewPortalUser";
import Data from "../components/Data/Data";
import Data2 from "../components/Data/Data2";
import Settings from "../components/Settings/Settings";
import Networks from "../components/Networks/Networks";
import IncidencesNew from "../components/Incidences/IncidencesNew";
import IncidentsPage from "../components/Incidences/IncidentsPage";
import IncidencesAllMap from "../components/Incidences/IncidencesAllMap";
import BillingAnalysis from "../components/Billing/BillingAnalysis";
import BillingDashboard from "../components/Billing/BillingDashboard";
import Billing from "../components/Billing/Billing";
import PublicUsers from "../components/Users/PublicUsers";
import BillingMap from "../components/Billing/BillingMap";
import ReportsData from "../components/Data/ReportsData";
import BillingData from "../components/Billing/BillingData";
import NotFound from "./404";
import DataIncidences from "../components/Data/DataIncidences";
import PortalUsersNew from "../components/UsersNew/PortalUsersNew";
import UserHome from "../components/UsersNew/UserHome";
import Categories from "../components/Categories/Categories";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const pathname = window.location.pathname.split("/");
  const jwt = require("jsonwebtoken");
  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  const token = localStorage.getItem("gdfhgfhtkn");
  var decoded = jwt.decode(token);
  const role = decoded?.Role;

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <Stats showing={showing} />}
            {pathname[1] === "networks" && <Networks showing={showing} />}

            {/* users section restricted access to admin */}
            {pathname[1] === "mobile" && pathname[2] === "Users" && (
              <MobileUsers />
            )}
            {pathname[1] === "mobile" &&
            pathname[2] === "New" &&
            role === "Admin" ? (
              <NewMobileUser />
            ) : (
              ""
            )}

            {pathname[1] === "portal" &&
            pathname[2] === "New" &&
            role === "Admin" ? (
              <PortalUsersNew />
            ) : (
              ""
            )}

            {pathname[1] === "users" && <UserHome />}

            {pathname[1] === "portal" &&
            pathname[2] === "Users" &&
            role === "Admin" ? (
              <PortalUsersNew />
            ) : (
              ""
            )}

            {pathname[1] === "public" &&
            pathname[2] === "Users" &&
            role === "Admin" ? (
              <PublicUsers />
            ) : (
              ""
            )}

            {/* billing section access restricted to Commercial, Management and Admin */}

            {pathname[1] === "billing" && pathname[2] === "Integration" && (
              <Billing />
            )}

            {pathname[1] === "billing" &&
            pathname[2] === "Dashboard" &&
            (role === "Admin" || role === "Commercial") ? (
              <BillingDashboard />
            ) : (
              ""
            )}

            {pathname[1] === "billing" &&
            pathname[2] === "Dashboard" &&
            role === "Management" ? (
              <BillingDashboard />
            ) : (
              ""
            )}

            {pathname[1] === "billing" &&
            pathname[2] === "Customers" &&
            role === "Admin" ? (
              <BillingData
                url="/customerbilling/paginated"
                title="Billing Data"
                search="by account no."
              />
            ) : (
              ""
            )}

            {pathname[1] === "billing" &&
            pathname[2] === "Customers" &&
            role === "Management" ? (
              <BillingData
                url="/customerbilling/paginated"
                title="Billing Data"
                search="by account no."
              />
            ) : (
              ""
            )}

            {pathname[1] === "billing" &&
            pathname[2] === "Customers" &&
            role === "Commercial" ? (
              <BillingData
                url="/customerbilling/paginated"
                title="Billing Data"
                search="by account no."
              />
            ) : (
              ""
            )}

            {/* incidences section restricted access to Admin, NRW/O&M and Management */}
            {pathname[1] === "incidences" &&
            pathname[2] === "Statistics" &&
            role === "Admin" ? (
              <IncidencesNew />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "Statistics" &&
            role === "NRW/O&M" ? (
              <IncidencesNew />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "Statistics" &&
            role === "Management" ? (
              <IncidencesNew />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "AllIncidences" &&
            role === "Admin" ? (
              <IncidencesAllMap />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "AllIncidences" &&
            role === "Management" ? (
              <IncidencesAllMap />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "AllIncidences" &&
            role === "NRW/O&M" ? (
              <IncidencesAllMap />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "Reports" &&
            role === "Admin" ? (
              <DataIncidences
                url="/reports/paginated"
                title="Incidences Data"
                search="by serial no."
              />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "Categories" &&
            role === "Admin" ? (
              <Categories url="/reports/categories" title="Categories" />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "Reports" &&
            role === "NRW/O&M" ? (
              <DataIncidences
                url="/reports/paginated"
                title="Incidences Data"
                search="by serial no."
              />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "Reports" &&
            role === "Management" ? (
              <DataIncidences
                url="/reports/paginated"
                title="Incidences Data"
                search="by serial no."
              />
            ) : (
              ""
            )}

            {pathname[1] === "incidences" &&
            pathname[2] === "Leakage" &&
            role === "Admin" ? (
              <IncidentsPage url="Leakage" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "Leakage" &&
            role === "NRW/O&M" ? (
              <IncidentsPage url="Leakage" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "SewerBursts" &&
            role === "Admin" ? (
              <IncidentsPage url="Sewer Burst" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "SewerBursts" &&
            role === "NRW/O&M" ? (
              <IncidentsPage url="Sewer Burst" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "IllegalConnections" &&
            role === "Admin" ? (
              <IncidentsPage url="Illegal Connection" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "IllegalConnections" &&
            role === "NRW/O&M" ? (
              <IncidentsPage url="Illegal Connection" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "SupplyFail" &&
            role === "Admin" ? (
              <IncidentsPage url="Supply Fail" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "SupplyFail" &&
            role === "NRW/O&M" ? (
              <IncidentsPage url="Supply Fail" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "Vandalism" &&
            role === "Admin" ? (
              <IncidentsPage url="Vandalism" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "Vandalism" &&
            role === "NRW/O&M" ? (
              <IncidentsPage url="Vandalism" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "Other" &&
            role === "Admin" ? (
              <IncidentsPage url="Other" />
            ) : (
              ""
            )}
            {pathname[1] === "incidences" &&
            pathname[2] === "Other" &&
            role === "NRW/O&M" ? (
              <IncidentsPage url="Other" />
            ) : (
              ""
            )}


            {pathname[1] === "data" &&
            pathname[2] === "Connections" &&
            role === "Admin" ? (
              <Data2
                url="/customers/paginated"
                title="Customers Data"
                search="by account no."
              />
            ) : (
              ""
            )}

            {pathname[1] === "data" &&
            pathname[2] === "Tanks" &&
            role === "Admin" ? (
              <Data
                url="/tanks/paginated"
                title="Tanks Data"
                search="by tank's name"
              />
            ) : (
              ""
            )}
            {pathname[1] === "data" &&
            pathname[2] === "Valves" &&
            role === "Admin" ? (
              <Data
                url="/valves/paginated"
                title="Valves Data"
                search="by valve's name"
              />
            ) : (
              ""
            )}
            {pathname[1] === "data" &&
            pathname[2] === "MasterMeters" &&
            role === "Admin" ? (
              <Data url="/mastermeters/paginated" title="Master Meters Data" />
            ) : (
              ""
            )}
            {pathname[1] === "data" &&
            pathname[2] === "Washouts" &&
            role === "Admin" ? (
              <Data url="/washouts/paginated" title="Washouts Data" />
            ) : (
              ""
            )}
            {pathname[1] === "data" &&
            pathname[2] === "Manholes" &&
            role === "Admin" ? (
              <Data url="/manholes/paginated" title="Manholes Data" />
            ) : (
              ""
            )}
            {pathname[1] === "data" &&
            pathname[2] === "Projects" &&
            role === "Admin" ? (
              <Data url="/projects/paginated" title="Projects Data" />
            ) : (
              ""
            )}

            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}
