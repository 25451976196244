import Header from "../Util/Header";
import { useEffect, useState } from "react";
import Maps from "../../components/Map/NetworksMap";

export default function Home(props) {
  const [active, setActive] = useState(null);
  const [data, setData] = useState(null);

  return (
    <div className="gis">
      <div className="map">
        <Maps />
      </div>
    </div>
  );
}
