import $ from "jquery";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Map from "ol/Map";
import { useState, useRef, useEffect } from "react";
import { Cluster, TileWMS, XYZ } from "ol/source";
import { ZoomToExtent, defaults as defaultControls } from "ol/control";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import GeoJSON from "ol/format/GeoJSON";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import RippleLoading from "../Util/RippleLoading";
import { Text } from "ol/style";
import Popup from "./NetworksPopup";
import myData from "../../assets/data/data";
import cmeter from "../../assets/imgs/cmeter.png";
import tank from "../../assets/imgs/tank.png";

const Basemap = (props) => {
  useEffect(() => {
    if (props.selected === props.index) {
      props.layer.setSource(
        new XYZ({
          url: props.url,
          crossOrigin: "Anonymous",
        })
      );
    }
  }, [props.selected]);

  return (
    <div className="item">
      <input
        type="checkbox"
        onChange={(e) => {
          props.setSelected(props.index);
        }}
        name=""
        id=""
        checked={props.selected === props.index ? true : false}
      />
      <label htmlFor="">{props.label}</label>
    </div>
  );
};

const Network = (props) => {
  useEffect(() => {
    if (props.netSelected === props.index) {
      props.setActiveUrl(props.url);
    }
  }, [props.netSelected]);

  return (
    <div className="item">
      <input
        type="checkbox"
        onChange={(e) => {
          props.setNetSelected(props.index);
        }}
        name=""
        id=""
        checked={props.netSelected === props.index ? true : false}
      />
      <label htmlFor="">{props.label}</label>
    </div>
  );
};

const Analysis = (props) => {
  return (
    <select
      onChange={(e) => {
        props.setAnalysis(e.target.value);
      }}
    >
      {props.data.map((item, index) => {
        return (
          <option value={item} key={index}>
            {item}
          </option>
        );
      })}
    </select>
  );
};

export default function Maps(props) {
  const pathname = window.location.pathname.split("/");

  let template = {
    Title: "",
    Category: props.theme,
    Description: "",
    Thumbnail: "",
    Dataset: "",
    Keywords: "",
    Owner: "",
    Type: "",
    Data: [],
    Status: "",
  };
  let colors = [
    "#241F07",
    "#4D6FA3",
    "#97DF59",
    "#800000",
    "#F43FF3",
    "#64A782",
    "#0CE668",
    "#EE496A",
    "#97DF59",
    "#BF1DF3",
    "#491FC6",
    "#703695",
    "#A091CE",
    "#21FFE5",
    "#FA19B6",
    "#E2FC56",
    "#F850DC",
    "#B5FB47",
    "#D36A0A",
    "#71B60D",
    "#286EE1",
    "#13AF16",
    "#657070",
    "#358F69",
    "#994AF2",
    "#C49C62",
    "#4DEF6B",
    "#799DD6",
    "#D4625D",
    "#E6CDE1",
    "#51A09B",
    "#61645E",
  ];
  let networks = [
    { name: "Utilities", url: "UtilityNetwork" },
    { name: "Sewer", url: "SewerNetwork" },
    { name: "Projects", url: "Projects" },
  ];
  const [activeUrl, setActiveUrl] = useState("ut");
  const [map, setMap] = useState();
  const [featuresLayer, setFeaturesLayer] = useState();
  const mapElement = useRef();
  const mapRef = useRef();
  mapRef.current = map;
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(false);
  const [legendItems, setLegendItems] = useState([]);
  const [legendItemsStyles, setLegendItemsStyles] = useState([]);
  const tooltip = useRef();
  const [showing, setShowing] = useState([]);
  const [many, setMany] = useState({ data: null, count: 0 });
  const [single, setSingle] = useState(null);
  const [vector, setVector] = useState(null);
  const [vLayer, setVLayer] = useState(new VectorLayer({ title: "networks" }));
  const [analysis, setAnalysis] = useState("Cluster");
  const [basemap, setBasemap] = useState(new TileLayer({ title: "basemaps" }));

  const [reports, setReports] = useState(new VectorLayer({ title: "reports" }));
  const [selected, setSelected] = useState(0);
  const [netSelected, setNetSelected] = useState(0);
  const [dataSelector, setDataSelector] = useState(null);
  const [styleSelector, setStyleSelector] = useState(null);
  const [querySelector, setQuerySelector] = useState(null);
  const [body, setBody] = useState(template);
  const [extent, setExtent] = useState([
    4153378.2713831086, -194668.47203224793, 4183418.0234991824,
    -175024.15576295764,
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const popup = new Overlay({
    element: tooltip.current,
  });

  let legItems = [];
  let legItemsStyles = [];

  useEffect(() => {
    const Landmarks = new TileLayer({
      title: "landmarks",
      extent: [
        37.05507278442383, -0.558377325534821, 37.19850540161133,
        -0.367469221353531,
      ],
      source: new TileWMS({
        url: `/api/geoserver/${pathname[2]}/wms`,
        params: { LAYERS: "UtilityNetwork:Landmarks", TILED: true },
        serverType: "geoserver",
        transition: 0,
      }),
    });

    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    });

    // create map
    const initialMap = new Map({
      target: mapElement.current,
      layers: [basemap, initalFeaturesLayer, vLayer, Landmarks],
      view: new View({
        projection: "EPSG:4326",
        center: [37.1274, -0.4832],
        zoom: 16,
        maxZoom: 32,
      }),
      controls: defaultControls().extend([
        new ZoomToExtent({
          extent: [
            37.05507278442383, -0.558377325534821, 37.19850540161133,
            -0.367469221353531,
          ],
        }),
      ]),
      renderer: "canvas",
    });

    initialMap.addOverlay(popup);

    initialMap.on("moveend", function (e) {
      setShowing([]);
      setMany({ data: null, count: null });
      setSingle(null);
    });

    initialMap?.on("singleclick", function (event) {
      setShowing([]);
      setMany({ data: null, count: null });
      setSingle(null);

      var feature = initialMap.getFeaturesAtPixel(event.pixel);

      if (feature?.length > 0) {
        let featureData = [];
        let layerName = null;

        feature.forEach((feat) => {
          const layer =
            feat?.values_?.layerName || feat?.values_?.layer?.get("title");
          if (!layerName) layerName = layer;

          let columnData = {};

          // Push all available columns dynamically
          Object.keys(feat?.values_ || {}).forEach((key) => {
            if (
              key !== "createdAt" &&
              key !== "updatedAt" &&
              key !== "geom" &&
              key !== "geometry" &&
              key !== "Coordinates" &&
              key !== "ID" &&
              key !== "User" &&
              key !== "Createdat" &&
              key !== "Updatedat" &&
              key !== "ObjectID"
            ) {
              columnData[key] = feat?.values_?.[key];
            }
          });

          featureData.push(columnData);
        });

        if (featureData.length === 1) {
          // Serialize data before setting state
          const serializedSingle = JSON.parse(JSON.stringify(featureData[0]));
          setSingle(serializedSingle);
          let s = [event.pixel[1], event.pixel[0]];
          if (showing.length === 0) {
            setShowing(s);
          }
        } else if (featureData.length > 1) {
          const serializedManyData = featureData
            .slice(0, 5)
            .map((item) => JSON.parse(JSON.stringify(item)));
          let d = { data: serializedManyData, count: featureData.length };
          setMany(d);
          let s = [event.pixel[1], event.pixel[0]];
          if (showing.length === 0) {
            setShowing(s);
          }
        }
      }
    });

    setMap(initialMap);
    setFeaturesLayer(initalFeaturesLayer);
  }, []);

  useEffect(() => {
    if (map) {
      // loadMapData();
      const headers = {
        Authorization: `Basic ${Buffer.from(
          "admin:geoserver",
          "utf-8"
        ).toString("base64")}`,
      };

      fetch(`/api/geoserver/rest/workspaces/${pathname[2]}/layers`, {
        headers: headers,
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          if (data.layers != "" && data.layers.layer.length > 0) {
            let d = [];
            for (let i = data.layers.layer.length; i > 0; i--) {
              setIndex(i);
              loadLayer(data.layers.layer[i - 1].name, colors[i]);
              d.push({ name: data.layers.layer[i - 1].name, color: colors[i] });
            }
            setLegendItems(d);
          }
        })
        .catch((e) => {});
    }
  }, [map, activeUrl]);

  useEffect(() => {
    if (vector) {
      reports.setSource(new VectorSource());
      reports.setSource(vector);
      reports.setStyle(reportsStyle);
      map.getLayers().forEach((layer) => {
        if (layer && layer.get("title") === "Heatmap") {
          map.removeLayer(layer);
        }
      });
    }
  }, []);

  function reportsStyle(feature) {
    const styleCache = {};

    let size = feature?.values_?.features?.length;
    let style = styleCache[size];
    if (!style) {
      if (size === 1) {
        style = new Style({
          image: new CircleStyle({
            radius: 10,
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            fill: new Fill({
              color: "#5BB318",
            }),
          }),
        });
        styleCache[size] = style;
      } else {
        let r = Math.ceil(size / 5);
        if (r < 10) r = 10;
        else if (r > 40) r = 40;
        style = new Style({
          image: new CircleStyle({
            radius: r,
            stroke: new Stroke({
              color: getColor(),
              width: 3,
            }),
            fill: new Fill({
              color: "#00D7FF",
            }),
          }),
          text: new Text({
            text: size.toString(),
            fill: new Fill({
              color: "#fff",
            }),
          }),
        });
        styleCache[size] = style;
      }
    }
    return style;
  }

  function getColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  async function loadLayer(layername, color) {
    try {
      map.getLayers().forEach((layer) => {
        if (
          layer &&
          layer.get("title") !== "basemaps" &&
          layer.get("title") !== "landmarks"
        ) {
          map.removeLayer(layer);
        }
      });
      setLoading(true);
      legItems.push(layername);
      var wrd = await $.ajax({
        url: getUrl(layername),
        dataType: "json",
        success: {},
        error: function (xhr) {
          setLoading(false);
        },
      });
      $.when(wrd).done(function (data) {
        let vLayer = new VectorLayer({
          title: layername,
        });

        const source = new VectorSource({
          features: new GeoJSON({
            dataProjection: "EPSG:4326",
          }).readFeatures(data),
        });

        if (
          data?.features[0].geometry &&
          (data?.features[0].geometry.type === "MultiPoint" ||
            data?.features[0].geometry.type === "Point")
        ) {
          // let color = "";
          let size = "";
          console.log(layername);

          if (layername == "Customer Connections") {
            // color = "#506ca4";
            size = 10; // Adjust the size value as needed
          } else if (layername === "Valves") {
            // color = "#00ff00";
            size = 9;
          } else if (layername == "Tanks") {
            // color = "#800020";
            size = 8;
          } else if (layername == "Manholes") {
            // color = "#964B00";
            size = 6;
          } else if (layername == "Great Chamber") {
            // color = "#008000";
            size = 10;
          } else if (layername == "Pumping Station") {
            // color = "#FFFF00";
            size = 10;
          } else if (layername == "SewerTreatmentKiagi") {
            // color = "#FFA500";
            size = 10;
          } else {
            // color = getRandomColor();
            size = 10;
          }

          vLayer = new VectorLayer({
            title: layername,
            declutter: true,
            source: source,
            style: function (feature) {
              const style = new Style({
                image: new CircleStyle({
                  radius: size,
                  stroke: new Stroke({
                    color: "#ffffff",
                    width: 3,
                  }),
                  fill: new Fill({
                    color: color,
                  }),
                }),
                fill: new Fill({
                  color: color,
                }),
                stroke: new Stroke({
                  color: "#0000FF",
                  width: 2,
                }),
              });

              legItemsStyles.push(style.stroke_["color_"]);
              return style;
            },
          });
          legItemsStyles.push(color);
        } else {
          // let color = "";
          // console.log(layername);

          // if (layername == "Sewer Line") color = "#00ff00";
          // else if (layername == "Pipelines") {
          //   color = "#0000FF";
          // } else color = getRandomColor();
          vLayer = new VectorLayer({
            declutter: true,
            title: layername,
            source: source,
            style: function (feature) {
              const style = new Style({
                image: new CircleStyle({
                  radius: 7,
                  stroke: new Stroke({
                    color: "#fff",
                    width: 1,
                  }),
                  fill: new Fill({
                    color: color,
                  }),
                }),
                fill: new Fill({
                  color: color,
                }),
                stroke: new Stroke({
                  color: color,
                  width: 2,
                }),
              });

              legItemsStyles.push(style.stroke_["color_"]);
              return style;
            },
          });
        }

        setLegendItemsStyles(legItemsStyles);
        setLoading(false);
        map.addLayer(vLayer);
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getUrl(url) {
    return `/api/geoserver/${pathname[2]}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${pathname[2]}:${url}&outputFormat=application/json`;
  }

  return (
    <div className="gis">
      <div className="mp">
        <div
          ref={mapElement}
          style={{ width: "100%", height: "100%" }}
          id="map"
        ></div>
        {showing?.length > 0 && (
          <Popup
            many={many}
            single={single}
            top={showing[0]}
            left={showing[1]}
          />
        )}
        <Layers
          basemap={basemap}
          networks={networks}
          setBasemap={setBasemap}
          selected={selected}
          setSelected={setSelected}
          netSelected={netSelected}
          setNetSelected={setNetSelected}
          utilities={reports}
          sewer={reports}
          projects={reports}
          setActiveUrl={setActiveUrl}
        />
        {loading && <RippleLoading />}
        {legendItems && <LegendGroup legendItems={legendItems} map={map} />}
      </div>
    </div>
  );
}

const LegendGroup = (props) => {
  return (
    <div className="networksLegend">
      {props.legendItems &&
        props.legendItems.map((item, i) => {
          return <LegendItem key={i} item={item} map={props.map} />;
        })}
    </div>
  );
};

const LegendItem = (props) => {
  const [showing, setShowing] = useState(true);

  useEffect(() => {
    if (props.map) {
      props.map.getLayers().forEach((layer) => {
        if (layer && layer.get("title") === props.item.name) {
          layer.setVisible(showing);
        }
      });
    }
  }, [showing]);

  return (
    <div style={{ marginBottom: "3px" }} className="cwrap">
      <input
        type="checkbox"
        checked={showing}
        onChange={(e) => {
          setShowing(e.target.checked);
        }}
      />
      <div
        style={{
          border: `2px solid yellow`,
          backgroundColor: props.item.color,
          height: "16px",
          width: "16px",
          borderRadius: "24px",
          fontSize: "x-large",
          textAlign: "center",
          lineHeight: "16px",
        }}
      ></div>
      <p>{props.item.name}</p>
    </div>
  );
};

const Layers = (props) => {
  const [display, setDisplay] = useState("none");
  return (
    <div
      className="layers"
      onMouseOut={() => {
        setDisplay("none");
      }}
      onMouseOver={() => {
        setDisplay("block");
      }}
    >
      <h3>
        Map Layers <i className="fa fa-angle-down"></i>
      </h3>
      <div className="container" style={{ display: display }}>
        <h4>Basemap</h4>
        <div className="basemaps">
          {myData.map((item, index) => {
            return (
              <Basemap
                key={index}
                index={index}
                label={item.name}
                layer={props.basemap}
                setLayer={props.setBasemap}
                url={item.url}
                selected={props.selected}
                setSelected={props.setSelected}
              />
            );
          })}
        </div>
        <h4>Networks</h4>
        <div className="basemaps">
          {props.networks.map((item, index) => {
            return (
              <Network
                key={index}
                index={index}
                label={item.name}
                layer={props.basemap}
                setLayer={props.setBasemap}
                url={item.url}
                netSelected={props.netSelected}
                setNetSelected={props.setNetSelected}
                setActiveUrl={props.setActiveUrl}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
