import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/users.scss";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import SelectedUser from "./SelectedUser";
import UserBox from "./UserBox";

export default function PublicUsers() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [userID, setUserID] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const selected = useRef();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/publicusers/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data?.result?.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    selectedUser();
  }, [userID]);

  const selectedUser = () => {
    setLoading(true);
    fetch(`/api/publicusers/${userID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setUserDetails(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function quickSearch(value) {
    setData(null);
    setLoading(true);
    fetch(`/api/publicusers/quicksearch/${value}/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data.result.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <div className="users">
      <div className="list">
        <div className="utp">
          <h3>Incident Reporter Users</h3>
          <div className="search">
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Name..."
              onChange={(e) => {
                const v = e.target.value;
                if (v !== "") {
                  quickSearch(v);
                } else {
                  setRefresh(!refresh);
                }
              }}
            />
            <FontAwesomeIcon className="fa-search" icon={faSearch} />
          </div>
        </div>
        <hr />
        {isMobile ? (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.result?.length > 0 &&
                    data?.result?.map((item, index) => {
                      return (
                        <UserBox
                          key={index}
                          item={item}
                          userID={userID}
                          setUserID={setUserID}
                          selected={selected}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>
            <div ref={selected}>
              <div className="selected">
                <h4>User Details</h4>
                <hr />

                {userDetails ? (
                  userDetails && (
                    <SelectedUser
                      setLoading={setLoading}
                      userDetails={userDetails}
                      setRefresh={setRefresh}
                      refresh={refresh}
                      url="mobile"
                    />
                  )
                ) : (
                  <>
                    <p>Click on a user to see their details</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="div31">
            <div>
              <div className="lcontainer">
                <div className="user-list">
                  {data &&
                    data?.result?.length > 0 &&
                    data?.result?.map((item, index) => {
                      return (
                        <UserBox
                          key={index}
                          item={item}
                          userID={userID}
                          setUserID={setUserID}
                          selected={null}
                        />
                      );
                    })}
                </div>
              </div>
              {data && (
                <Pagination
                  totalItems={data?.total}
                  currentPage={offset}
                  onPageChange={(v) => {
                    setOffset(v);
                  }}
                />
              )}
            </div>
            <div className="selected">
              <h4>User Details</h4>
              <hr />

              {userDetails ? (
                userDetails && (
                  <SelectedUser
                    setLoading={setLoading}
                    userDetails={userDetails}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    url="mobile"
                  />
                )
              ) : (
                <>
                  <p>Click on a user to see their details</p>
                </>
              )}
            </div>
          </div>
        )}

        {loading && <Loading />}
      </div>
    </div>
  );
}
