import "../../Styles/billing.scss";
import React, { useEffect, useRef, useState } from "react";
import Header from "../Util/Header";
import Button from "../Util/Button";
import "../../Styles/stats.scss";

export default function Billing(props) {
  const [customersUrban, setCustomersUrban] = useState(null);
  const [urban, setUrban] = useState(null);
  const [rural, setRural] = useState(null);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const totalurban = useRef();
  const totalrural = useRef();
  const integration = useRef();
  const total = useRef();

  useEffect(() => {
    if (urban !== null && urban < 8) {
      fetch(`/api/update/1/${urban * 3000}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          totalurban.current.textContent =
            parseInt(totalurban.current.textContent) + data.number;
          let holder = urban + 1;
          setUrban(holder);
          let tt =
            parseInt(totalurban.current.textContent) +
            parseInt(totalrural.current.textContent);
          total.current.textContent = "Total: " + tt;
        });
    } else {
      setLoading1(false);
    }
  }, [urban]);

  useEffect(() => {
    if (rural !== null && rural < 5) {
      fetch(`/api/update/2/${rural * 3000}`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          totalrural.current.textContent =
            parseInt(totalrural.current.textContent) + data.number;
          let holder = rural + 1;
          setRural(holder);
          let tt =
            parseInt(totalurban.current.textContent) +
            parseInt(totalrural.current.textContent);
          total.current.textContent = "Total: " + tt;
        });
    } else {
      setLoading2(false);
    }
  }, [rural]);

  function updateLocal() {
    fetch(`/api/localupdate`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        integration.current.textContent =
          "Integration Tally" + data.number + "/" + data.total;
      });
  }

  const onClick = () => {
    totalrural.current.textContent = 0;
    totalurban.current.textContent = 0;
    total.current.textContent = "Total: 0";
    integration.current.textContent = "Integration Tally: 0";
    setUrban(0);
    setRural(0);
    setLoading1(true);
    setLoading2(true);
  };

   return (
     <div className="billing">
       <div className="integration">
         <div className="wrapper">
           <h1>Integration Results</h1>
           <div className="results">
             <div className="schemerural">
               <h3>Scheme: Rural</h3>
               <br></br>

               <div ref={totalurban}>0</div>
               {loading1 && (
                 <div className="lds-roller">
                 </div>
               )}
             </div>
             <div className="schemeurban">
               <h3>Scheme: Urban</h3>
               <br></br>

               <div ref={totalrural}>0</div>
               {loading2 && (
                 <div className="lds-roller">
                 </div>
               )}
             </div>
           </div>
           <br></br>

           <h2 ref={total}>Total: 0</h2>
           <br></br>
           <br></br>
           <h2 ref={integration}>Integration Tally: 0</h2>
           <br></br>

           <Button handleClick={() => onClick()} value="Run Integration" />
         </div>
       </div>
     </div>
   );
}
