import "../../Styles/billingstats.scss";
import { useRef, useState } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import XYZ from "ol/source/XYZ";
import Overlay from "ol/Overlay";
import { MdOutlinePayments } from "react-icons/md";
import { FcPaid } from "react-icons/fc";
import ReactDOMServer from "react-dom/server";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import Graticule from "ol/layer/Graticule";

export default function BillingData(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState(false);
  const [column, setColumn] = useState("Name");
  const [v, setV] = useState("");
  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);
  const [searched, setSearched] = useState(null);

  useEffect(() => {
    if (!v) {
      if (!fcolumn || !fvalue || !foperator) {
        setIsLoading(true);
        fetch(`/api/customers/paginated/${offset}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setIsLoading(false);
            if (data?.data?.length > 0) {
              setData(data);
            } else setData(null);
          })
          .catch((e) => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        fetch(
          `/api/customers/filter/${fcolumn}/${foperator}/${fvalue}/${offset}`
        )
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setIsLoading(false);
            setData(data);
          })
          .catch((e) => {
            setIsLoading(false);
          });
      }
    }
  }, [offset, refresh, fvalue, foperator, fcolumn, v]);

  useEffect(() => {
    searchCustomer(v);
  }, [v, offset, refresh]);

  async function searchCustomer(v) {
    setIsLoading(true);
    fetch(`/api/customers/paginated/search/${column}/${v}/${offset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        console.log(data);
        setData(data);
        setSearched(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }

  const saveData = (data) => {
    let rows = [];
    rows.push(Object.keys(data?.data[0]));
    data?.data?.forEach((item) => {
      // Map each item's values and enclose them in double quotes
      let rowValues = Object.values(item).map((value) => `"${value}"`);
      rows.push(rowValues);
    });

    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="billingstats">
      <div className="list">
        <div className="bar">
          <div className="left">
            <h3>Customer Connections</h3>
          </div>

          <div className="search">
            <select
              onChange={(e) => {
                setColumn(e.target.value);
              }}
              name=""
              id=""
            >
              <option value="Name">Name</option>
              <option value="Account No">Account No</option>
              <option value="Zone">Zone</option>
              <option value="DMA">DMA</option>
              <option value="Route">Route</option>
            </select>
            <input
              onChange={(e) => {
                if (e.target.value == "") {
                  setRefresh(!refresh);
                  setOffset(0);
                } else {
                  setV(e.target.value);
                }
              }}
              type="text"
              placeholder="Select column to search"
              required
            />
            <i className="fa fa-search"></i>
            {searched && (
              <FontAwesomeIcon
                onClick={() => {
                  saveData(searched);
                }}
                className="download"
                icon={faDownload}
              />
            )}
          </div>
        </div>
        <div className="filter">
          <div
            onClick={() => {
              if (!filter) {
                setFilter(true);
                setFColumn("Amount");
                setFOperator("=");
              } else {
                setFilter(false);
                setFColumn(null);
                setFOperator(null);
                setFValue(null);
              }
            }}
            className="add"
          >
            <i className="fa fa-plus-circle"></i>
            <p>{filter ? "Close Filter" : "Add Filter"}</p>
          </div>
          {filter && (
            <div className="math">
              <select
                onChange={(e) => {
                  setFColumn(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="Zone">Zone</option>
                <option value="DMA">DMA</option>
                <option value="AccountNo">Account Number</option>
              </select>
              <select
                onChange={(e) => {
                  setFOperator(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="=">Equal To</option>
                <option value=">">Greater Than</option>
                <option value="<">Less Than</option>
              </select>
              <input
                onChange={(e) => {
                  if (e.target.value == "") {
                    setFValue(null);
                  } else {
                    setFValue(e.target.value);
                  }
                }}
                type="number"
                name=""
                id=""
                placeholder="Value"
                required
              />
              <button
                onClick={() => {
                  setFilter(false);
                }}
              >
                Add
              </button>
            </div>
          )}
          {fcolumn && foperator && fvalue && (
            <div className="filter">
              <p>{fcolumn}</p>
              <h6>{foperator}</h6>
              <p>{fvalue}</p>
              <i
                onClick={() => {
                  setFColumn(null);
                  setFOperator(null);
                  setFValue(null);
                }}
                className="fa fa-times"
              ></i>
            </div>
          )}
        </div>
        <div className="container">
          <div className="cushead">
            <h4>Name</h4>
            <h4>Account No</h4>
            <h4>Zone</h4>
            <h4>DMA</h4>
            <h4>Account Status</h4>
          </div>
          {data &&
            data?.data?.length > 0 &&
            data?.data?.map((item, i) => {
              return <Item key={i} index={i} item={item} />;
            })}
          <br />
          <br />
          {data?.total > 0 && (
            <Pagination
              totalItems={data?.total}
              onPageChange={(v) => {
                setOffset(v);
              }}
              currentPage={offset}
            />
          )}
        </div>
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const Popup = (props) => {
  const [subcounty, setSubCounty] = useState(
    new VectorLayer({ title: "Sub Counties" })
  );
  const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 2,
        lineDash: [0.5, 8],
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid",
    })
  );
  const [data, setData] = useState(null);
  const [active, setActive] = useState("Data");
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  mapElement.current = map;

  useEffect(() => {
    if (active == "Map") {
      basemap.setSource(
        new XYZ({
          url:
            "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}" +
            "?access_token=pk.eyJ1IjoiZ2F0aG9nbzEiLCJhIjoiY2t0djhndnB4MGkzdDJucDg2bW5uNXNrcyJ9.mnbTMXxDrdYnTrb8Gr7_MA",
          crossOrigin: "anonymous",
        })
      );
      const initialMap = new Map({
        target: mapElement.current,
        layers: [basemap, subcounty, ward, graticule],
        view: new View({
          projection: "EPSG:4326",
          center: [36.45, -0.1],
          zoom: 12,
          maxZoom: 20,
        }),
        controls: defaultControls().extend([
          new ZoomToExtent({
            extent: [34.36168, 0.41839, 35.06887, 1.14702],
          }),
          new ScaleLine({
            units: "metric",
            bar: false,
            text: "Scale",
          }),
        ]),
      });

      setMap(initialMap);
    }
  }, [active]);

  useEffect(() => {
    if (active == "Map" && map) {
      fetch(`/api/Customers/${props.data.ID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          // console.log(data);

          const geojsonObject = {
            type: "Feature",
            geometry: data.geom,
            properties: data,
          };
          const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(geojsonObject),
          });

          const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({
                  color: "rgba(255, 0, 0, 0.6)",
                }),
                stroke: new Stroke({
                  color: "#319FD3",
                  width: 1,
                }),
              }),
            }),
          });

          map.addLayer(vectorLayer);

          // Create a popup overlay
          const popup = new Overlay({
            element: document.getElementById("popup"),
            positioning: "bottom-center",
            stopEvent: false,
            offset: [0, -10],
          });
          map.addOverlay(popup);

          // Add a click event to the vector layer to show the popup
          map.on("click", (event) => {
            map.forEachFeatureAtPixel(event.pixel, (feature) => {
              const coordinates = feature.getGeometry().getCoordinates();
              popup.setPosition(coordinates);
              document.getElementById("popup-content").innerHTML =
                ReactDOMServer.renderToString(<MapPopup data={data[0]} />);
              popup.setOffset([0, -20]); // Adjust the popup offset to prevent it from blocking the feature
            });
          });

          const extent = vectorSource.getExtent();
          map.getView().fit(extent, { padding: [50, 50, 50, 50] });

          return () => {
            map.setTarget(null);
          };
        })
        .catch((e) => {});
    }
  }, [active, map]);

  const MapPopup = (params) => {
    return (
      <div className="mapopup">
        <Row item={["Name", params.data.Name]} />
        <Row item={["Route", params.data.Route]} />
        <Row item={["Zone", params.data.Zone]} />
        <Row item={["DMA", params.data.DMA]} />
        <Row item={["Location", params.data.Location]} />
      </div>
    );
  };

  useEffect(() => {
    let d = props.data;
    delete d.geom;
    const cols = Object.entries(d);
    // console.log(cols);
    setData(cols);
  }, []);

  const Row = (params) => {
    return (
      <div className="prow">
        <p>{params.item[0]}</p>
        <p>{params.item[1]}</p>
      </div>
    );
  };

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={params.active == params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  return (
    <div data-aos="fade-left" className="bpopup">
      <div className="bcontainer">
        <div className="dets">
          <h3>{props?.data.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="pbar">
          <Bar txt="Data" active={active} />
          <Bar txt="Map" active={active} />
        </div>
        {active == "Data" && (
          <div className="content">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return <Row key={i} item={item} />;
              })}
          </div>
        )}
        {active == "Map" && (
          <div>
            <div ref={mapElement} className="pmap"></div>
            <div id="popup" className="ol-popup">
              <div id="popup-content"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const [showing, setShowing] = useState(null);
  const [cl, setCl] = useState("white");
  const colors = [
    { name: "CHANGE_OF_NAME", color: "#898121" },
    { name: "CANCELLED", color: "#FFEADD" },
    { name: "DORMANT", color: "#E7B10A" },
    { name: "ACTIVE", color: "#1A5D1A" },
    { name: "PENDING_PAYMENT", color: "#FF9B9B" },
    { name: "SEALED", color: "#4E4FEB" },
    { name: "CUT-OFF", color: "#068FFF" },
  ];

  useEffect(() => {
    const i = colors
      .map((e) => {
        return e.name;
      })
      .indexOf(props.item.AccountStatus);
    if (i != -1) {
      setCl(colors[i].color);
    }
  }, [props.item.AccountStatus]);

  function withCommas(x) {
    return (Math.round(x * 100) / 100)
      .toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <>
      {" "}
      <div
        className="cusrow"
        onClick={() => {
          setShowing(true);
        }}
      >
        <div>
          <p>{props.item.Name?.toLowerCase()}</p>
        </div>
        <div>
          <p>{props.item.AccountNo?.toLowerCase()}</p>
        </div>
        <div>
          <p>{props.item.Zone?.toLowerCase()}</p>
        </div>
        <div>
          <p>{props.item.DMA?.toLowerCase()}</p>
        </div>
        <div>
          <p>{props.item.AccountStatus?.toLowerCase()}</p>
        </div>
      </div>
      {showing && (
        <Popup
          showing={showing}
          setShowing={setShowing}
          data={props.item}
          url={props.url}
        />
      )}
    </>
  );
};
