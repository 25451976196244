import "../Styles/login.scss";
import Button from "../components/Util/Button";
import Input from "../components/Util/Input";
import logo from "../assets/imgs/logo.png";
import { useRef, useState } from "react";
import Loading from "../components/Util/Loading";
import ForgetPassword from "../components/Login/ForgetPassword";
import left_ico from "../assets/imgs/left_icon.png";
import Confetti from "react-confetti";
import { useLayoutEffect } from "react";

export default function Login(props) {
  const rfEmail = useRef();
  const rfPassword = useRef();
  const [isErr, setIsErr] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [body, updateBody] = useState({
    Email: null,
    Password: null,
  });
  const ref = useRef();
  const [toggleForgot, setToggleForgot] = useState(false);

  useLayoutEffect(() => {}, []);

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.getValue().toLowerCase().trim();
    d.Password = rfPassword.current.getValue();
    updateBody(d);
    setIsErr("");

    if (!validateEmail(body.Email))
      return setIsErr("Please Enter a Valid Email Address!");
    if (!validatePassword(body.Password))
      return setIsErr("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      setIsLoading(true);
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          if (data.success) {
            localStorage.setItem("gdfhgfhtkn", data.token);
            setIsErr(data.success);
            setIsLoading(false);
            window.location.href = "/";
          } else {
            setIsLoading(false);
            setIsErr(data.error);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsErr("Login failed");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  return (
    <div ref={ref} className="login">
      <div className="overlay">
        <div className="card">
          <div className="leftdeco">
            <img src={logo} alt="" />
            <div>
              <h1>Mathira Water</h1>
              <h2>MAWASCO</h2>
            </div>
          </div>
          <div className="container">
            <div className="div2equal">
              <div className="left">
                <img src={left_ico} alt="" />
              </div>
              <div className="right">
                <h1>Utility Manager</h1>
                <p className="err">{isErr}</p>
                <form
                  action=""
                  onSubmit={(e) => {
                    e.preventDefault(e);
                  }}
                >
                  <Input
                    ref={rfEmail}
                    type="text"
                    label="Email Address"
                    placeholder="Email Address"
                  />
                  <Input
                    ref={rfPassword}
                    type="password"
                    label="Password"
                    placeholder="Password"
                  />
                  <p>
                    Forgot password?{" "}
                    <a
                      onClick={() => {
                        setToggleForgot(true);
                      }}
                    >
                      click here
                    </a>
                  </p>
                  <Button handleClick={login} value="Submit" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          Powered by <a href="https://osl.co.ke">Oakar Services</a>
        </div>
        {loading && <Loading />}
        {toggleForgot && <ForgetPassword setToggleForgot={setToggleForgot} />}
      </div>
    </div>
  );
}
