import Header from "../Util/Header";
import { useEffect, useRef, useState } from "react";
import { FaMoneyCheckAlt, FaFileInvoice } from "react-icons/fa";
import { BsCurrencyDollar } from "react-icons/bs";
import $ from "jquery";
import icon from "leaflet/dist/images/marker-icon.png";
import L from "leaflet";
import CMPie from "../Util/CMAnalysisPie";
import CMBarchart from "../Util/CMAnalysisBar";
import Maps from "../Map/AnalysisMap";
import "../../Styles/analysis.scss";

let DefaultIcon = L.icon({
  iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

export default function Analysis(props) {
  const [data, setData] = useState(null);
  const [searchBy, setSearchBy] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const searchRef = useRef();
  const [searchShown, setsearchShown] = useState(false);

  useEffect(() => {
    var response = $.ajax({
      url: `/api/geoserver/UtilityNetwork/wfs?request=GetFeature&version=1.0.0&typeName=CustomerMeters&outputFormat=json`,
      dataType: "json",
      error: function (xhr) {
        setData(null);
      },
    });

    $.when(response).done(function (data) {
      setData(data);
    });
  }, []);

  useEffect(() => {
    updateCurrent();
  }, [data]);

  let colors = [
    "#241F07",
    "#4D6FA3",
    "#6E4181",
    "#F43FF3",
    "#64A782",
    "#EE496A",
    "#97DF59",
    "#BF1DF3",
    "#491FC6",
    "#703695",
    "#A091CE",
    "#21FFE5",
    "#FA19B6",
    "#E2FC56",
    "#F850DC",
    "#B5FB47",
    "#D36A0A",
    "#71B60D",
    "#286EE1",
    "#13AF16",
    "#657070",
    "#358F69",
    "#994AF2",
    "#C49C62",
    "#4DEF6B",
    "#799DD6",
    "#D4625D",
    "#E6CDE1",
    "#51A09B",
    "#61645E",
  ];

  const [type, setType] = useState("CurrentBalance");
  const [stats, setStats] = useState(false);
  const [arrows, setArrows] = useState("fa fa-angle-double-up");
  const [CurrentBalance, setCurrentBalance] = useState("button active");
  const [InvoiceAmount, setInvoiceAmount] = useState("button");
  const [PreviousBalance, setPreviousBalance] = useState("button");
  const [AccountStatus, setAccountStatus] = useState("button");
  const [analysis, setAnalysis] = useState(false);

  let cCurrentBalance = [
    colors[0],
    colors[1],
    colors[2],
    colors[3],
    colors[4],
    colors[5],
    colors[6],
    colors[7],
    colors[8],
    colors[9],
  ];

  const [myColors, setMyColors] = useState(cCurrentBalance);
  const template = [
    { name: "below 0", value: 0, fill: myColors[0] },
    { name: "0-250", value: 0, fill: myColors[1] },
    { name: "250-500", value: 0, fill: myColors[2] },
    { name: "500-1000", value: 0, fill: myColors[3] },
    { name: "1000-5000", value: 0, fill: myColors[4] },
    { name: "5000-10000", value: 0, fill: myColors[5] },
    { name: "10000-20000", value: 0, fill: myColors[6] },
    { name: "Above 20000", value: 0, fill: myColors[7] },
    { name: "N/A", value: 0, fill: "blue" },
  ];

  const template2 = [
    { name: "ACTIVE", value: 0, fill: myColors[0] },
    { name: "DORMANT", value: 0, fill: myColors[1] },
    { name: "SEALED", value: 0, fill: myColors[2] },
    { name: "CUT OFF", value: 0, fill: myColors[3] },
    { name: "CLOSED", value: 0, fill: myColors[4] },
    { name: "N/A", value: 0, fill: "blue" },
  ];

  const [chartData, setChartData] = useState(template);
  const [chartData2, setChartData2] = useState(template2);
  const [realData, setRealData] = useState(null);
  const [piechart, showPieChart] = useState(true);
  const [showBarChart, setShowBarChart] = useState(true);
  const [showAnalysis, setShowAnalysis] = useState(true);

  const changeStats = (type) => {
    switch (type) {
      case "CurrentBalance":
        setAnalysis("Current Balance");
        updateCurrent();
        setType(type);
        setCurrentBalance("button active");
        setInvoiceAmount("button");
        setPreviousBalance("button");
        setAccountStatus("button");
        break;
      case "InvoiceAmount":
        setAnalysis("Invoice Amount");
        updateInvoice();
        setType(type);
        setCurrentBalance("button");
        setInvoiceAmount("button active");
        setPreviousBalance("button");
        setAccountStatus("button");
        break;
      case "PreviousBalance":
        setAnalysis("Previous Balance");
        updatePrevious();
        setType(type);
        setCurrentBalance("button");
        setInvoiceAmount("button");
        setPreviousBalance("button active");
        setAccountStatus("button");
        break;
      case "AccountStatus":
        setAnalysis("Account Status");
        updateAccStatus();
        setType(type);
        setCurrentBalance("button");
        setInvoiceAmount("button");
        setPreviousBalance("button");
        setAccountStatus("button active");
        break;
      default:
        break;
    }
  };

  const updateCurrent = () => {
    if (data) {
      data.features.forEach((item) => {
        let currentbalance = item.properties.currentbal;
        let dummy = chartData;
        if (currentbalance < 0) {
          let values = dummy[0];
          values.value = values.value + 1;
          dummy[0] = values;
        } else if (currentbalance >= 0 && currentbalance < 250) {
          let values = dummy[1];
          values.value = values.value + 1;
          dummy[1] = values;
        } else if (currentbalance >= 250 && currentbalance < 500) {
          let values = dummy[2];
          values.value = values.value + 1;
          dummy[2] = values;
        } else if (currentbalance >= 500 && currentbalance < 1000) {
          let values = dummy[3];
          values.value = values.value + 1;
          dummy[3] = values;
        } else if (currentbalance >= 1000 && currentbalance < 5000) {
          let values = dummy[4];
          values.value = values.value + 1;
          dummy[4] = values;
        } else if (currentbalance >= 5000 && currentbalance < 10000) {
          let values = dummy[5];
          values.value = values.value + 1;
          dummy[5] = values;
        } else if (currentbalance >= 10000 && currentbalance < 20000) {
          let values = dummy[6];
          values.value = values.value + 1;
          dummy[6] = values;
        } else if (currentbalance >= 20000) {
          let values = dummy[7];
          values.value = values.value + 1;
          dummy[7] = values;
        } else if (currentbalance == null) {
          let values = dummy[8];
          values.value = values.value + 1;
          dummy[8] = values;
        }
        setChartData(dummy);
      });

      setRealData(null);
      setRealData(chartData);
      setChartData(template);
    }
  };
  const updatePrevious = () => {
    if (data) {
      data.features.forEach((item) => {
        let previousbalance = item.properties.previousba;
        let dummy = chartData;
        if (previousbalance < 0) {
          let values = dummy[0];
          values.value = values.value + 1;
          dummy[0] = values;
        } else if (previousbalance >= 0 && previousbalance < 250) {
          let values = dummy[1];
          values.value = values.value + 1;
          dummy[1] = values;
        } else if (previousbalance >= 250 && previousbalance < 500) {
          let values = dummy[2];
          values.value = values.value + 1;
          dummy[2] = values;
        } else if (previousbalance >= 500 && previousbalance < 1000) {
          let values = dummy[3];
          values.value = values.value + 1;
          dummy[3] = values;
        } else if (previousbalance >= 1000 && previousbalance < 5000) {
          let values = dummy[4];
          values.value = values.value + 1;
          dummy[4] = values;
        } else if (previousbalance >= 5000 && previousbalance < 10000) {
          let values = dummy[5];
          values.value = values.value + 1;
          dummy[5] = values;
        } else if (previousbalance >= 10000 && previousbalance < 20000) {
          let values = dummy[6];
          values.value = values.value + 1;
          dummy[6] = values;
        } else if (previousbalance >= 20000) {
          let values = dummy[7];
          values.value = values.value + 1;
          dummy[7] = values;
        } else if (previousbalance === null) {
          let values = dummy[8];
          values.value = values.value + 1;
          dummy[8] = values;
        }
        setChartData(dummy);
      });

      setRealData(null);
      setRealData(chartData);
      setChartData(template);
    }
  };
  const updateInvoice = () => {
    if (data) {
      data.features.forEach((item) => {
        let invoiceamount = item.properties.invoiceamo;
        let dummy = chartData;
        if (invoiceamount < 0) {
          let values = dummy[0];
          values.value = values.value + 1;
          dummy[0] = values;
        } else if (invoiceamount >= 0 && invoiceamount < 200) {
          let values = dummy[1];
          values.value = values.value + 1;
          dummy[1] = values;
        } else if (invoiceamount >= 250 && invoiceamount < 500) {
          let values = dummy[2];
          values.value = values.value + 1;
          dummy[2] = values;
        } else if (invoiceamount >= 500 && invoiceamount < 1000) {
          let values = dummy[3];
          values.value = values.value + 1;
          dummy[3] = values;
        } else if (invoiceamount >= 1000 && invoiceamount < 5000) {
          let values = dummy[4];
          values.value = values.value + 1;
          dummy[4] = values;
        } else if (invoiceamount >= 5000 && invoiceamount < 10000) {
          let values = dummy[5];
          values.value = values.value + 1;
          dummy[5] = values;
        } else if (invoiceamount >= 10000 && invoiceamount < 20000) {
          let values = dummy[6];
          values.value = values.value + 1;
          dummy[6] = values;
        } else if (invoiceamount >= 20000) {
          let values = dummy[7];
          values.value = values.value + 1;
          dummy[7] = values;
        } else if (invoiceamount === null) {
          let values = dummy[8];
          values.value = values.value + 1;
          dummy[8] = values;
        }
        setChartData(dummy);
      });

      setRealData(null);
      setRealData(chartData);
      setChartData(template);
    }
  };
  const updateAccStatus = () => {
    if (data) {
      data.features.forEach((item) => {
        let accstatus = item.properties.accstatus;
        let dummy = chartData2;
        if (accstatus == "ACTIVE") {
          let values = dummy[0];
          values.value = values.value + 1;
          dummy[0] = values;
        } else if (accstatus == "DORMANT") {
          let values = dummy[1];
          values.value = values.value + 1;
          dummy[1] = values;
        } else if (accstatus == "SEALED") {
          let values = dummy[2];
          values.value = values.value + 1;
          dummy[2] = values;
        } else if (accstatus == "CUT OFF") {
          let values = dummy[3];
          values.value = values.value + 1;
          dummy[3] = values;
        } else if (accstatus == "CLOSED") {
          let values = dummy[4];
          values.value = values.value + 1;
          dummy[4] = values;
        }
        setChartData2(dummy);
      });

      setRealData(null);
      setRealData(chartData2);
      setChartData2(template2);
    }
  };

  const toggleStats = () => {
    setStats(!stats);
    let cl = !stats ? "fa fa-angle-double-down" : "fa fa-angle-double-up";
    setArrows(cl);
  };

  const displaySearched = () => {
    if (searchRef.current.value.length > 0) {
      setSearchValue(searchRef.current.value);
    }
  };

  const analysisStyle = showAnalysis ? "topbarVisible" : "topbarHidden";
  const searchStyle = showAnalysis ? "searchVisible" : "searchHidden";

  return (
    <div className="analysis">
      <div className="map">
        <div className="gis">
          <div className="maps">
            <Maps
              zoomextend="14"
              url="UtilityNetwork"
              analysis={analysis}
              setAnalysis={setAnalysis}
              setShowAnalysis={setShowAnalysis}
              searchBy={searchBy}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              showAnalysis={showAnalysis}
            />

            <div className={analysisStyle}>
              <div
                className={CurrentBalance}
                onClick={() => {
                  changeStats("CurrentBalance");
                }}
              >
                <FaMoneyCheckAlt size={20} color={"#026749"} />{" "}
                &nbsp;&nbsp;&nbsp;
                <p>Current balance</p>
              </div>
              <div
                className={InvoiceAmount}
                onClick={() => {
                  changeStats("InvoiceAmount");
                }}
              >
                <FaFileInvoice size={20} color={"#026749"} /> &nbsp;&nbsp;&nbsp;
                <p>Invoice amount</p>
              </div>
              <div
                className={PreviousBalance}
                onClick={() => {
                  changeStats("PreviousBalance");
                }}
              >
                <BsCurrencyDollar size={20} color={"#026749"} />{" "}
                &nbsp;&nbsp;&nbsp;
                <p>Previous balance</p>
              </div>
              <div
                className={AccountStatus}
                onClick={() => {
                  changeStats("AccountStatus");
                }}
              >
                <BsCurrencyDollar size={20} color={"#026749"} />{" "}
                &nbsp;&nbsp;&nbsp;
                <p>Account Status</p>
              </div>
            </div>

            <div className="searchbutton">
              <div>
                <a
                  onClick={() => {
                    setsearchShown(true);
                  }}
                  role="button"
                >
                  <i className="fa fa-search"></i>Search
                </a>

                {searchShown && (
                  <div className={searchStyle}>
                    <div className="searches">
                      <select
                        name="select"
                        defaultValue="Search by"
                        id=""
                        onChange={(e) => setSearchBy(e.target.value)}
                      >
                        <option value="" selected hidden>
                          Search by
                        </option>
                        <option value="Meter Number">Meter No</option>
                        <option value="name">Name</option>
                        <option value="currentbal">Current Bal</option>
                        <option value="brand">Brand</option>
                        <option value="account">Account No</option>
                      </select>
                      {searchBy ? (
                        <>
                          <input
                            type="text"
                            className="searchTerm"
                            id="input_text"
                            placeholder={searchBy}
                            ref={searchRef}
                          ></input>
                          <button
                            type="submit"
                            className="searchButton"
                            onClick={displaySearched}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            className="searchTerm"
                            id="input_text"
                            placeholder={searchBy}
                            ref={searchRef}
                            disabled
                          ></input>
                          <button
                            type="submit"
                            className="searchButton"
                            onClick={displaySearched}
                            disabled
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {showAnalysis && (
              <div className="statistics">
                <div className="container">
                  <div className="title">
                    <i
                      onClick={() => {
                        toggleStats();
                      }}
                      className={arrows}
                    ></i>
                    <h4>Statistics</h4>
                    <i
                      onClick={() => {
                        toggleStats();
                      }}
                      className={arrows}
                    ></i>
                  </div>
                  {stats && (
                    <div className="content">
                      {piechart && <CMPie data={realData} />}
                      {showBarChart && <CMBarchart data={realData} />}
                      <div className="other">
                        <div className="othertext">
                          <h3>{type}</h3>
                          <p>
                            A classification based on {type}. Hover over the
                            chart to get more information.
                          </p>
                        </div>
                      </div>
                      <div className="otherCharts">
                        <p
                          className="charts"
                          onClick={() => {
                            showPieChart(false);
                            setShowBarChart(true);
                          }}
                        >
                          Bar Chart
                        </p>
                        <p
                          className="charts"
                          onClick={() => {
                            showPieChart(true);
                            setShowBarChart(false);
                          }}
                        >
                          Pie Chart
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
