import React, { useState } from "react";
import "../../Styles/userhome.scss";
import MUsers from "./MobileUsers";
import PortalUsers from "./PortalUsersNew";
import PublicUsers from "./PublicUsers";

export default function UserHome(props) {
  const [active, setActive] = useState("Portal Users");

  return (
    <div className="userhome">
      <div className="vtop">
        <Item txt="Portal Users" active={active} setActive={setActive} />
        <Item txt="Mobile Users" active={active} setActive={setActive} />
        <Item txt="Public Users" active={active} setActive={setActive} />
      </div>
      {active === "Portal Users" && <PortalUsers />}
      {active === "Mobile Users" && (
        <MUsers active={active} setActive={setActive} />
      )}
      {active === "Public Users" && (
        <PublicUsers active={active} setActive={setActive} />
      )}
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className={props.active === props.txt ? "vactive" : "vitem"}
    >
      <p>{props.txt}</p>
    </div>
  );
};
